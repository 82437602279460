import React from "react"
import "./filter.scss"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const FilterButton = (props) => {
  const { handleClick } = props

  const handleFlterClick = () => {
    if (!handleClick) return

    handleClick()
  }

  const data = useStaticQuery(graphql`
    query {
      filterimg: file(
        relativePath: { eq: "filter_list.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <span
      className="filterButtonCover"
      onClick={handleFlterClick}
    >
      <Img fluid={data.filterimg.childImageSharp.fluid} />
      <span className="filterButtonText">All Courses</span>
    </span>
  )
}

export default FilterButton
